import React from "react";
import PropTypes from "prop-types";
import { Link as GatsbyLink } from "gatsby";
import {
  Link as MUILink,
  SvgIcon,
  Typography,
  makeStyles,
} from "@material-ui/core";
import Icon from "@mdi/react";
import { mdiDockWindow } from "@mdi/js";

const Link = ({ to, children, variant = "body1" }) => {
  const classes = useStyles();
  const external = to.startsWith("http");

  if (external) {
    return (
      <MUILink href={to} rel="noopener noreferrer">
        <Typography component="span" variant={variant}>
          {children}
          <SvgIcon className={classes.icon}>
            <Icon path={mdiDockWindow} />
          </SvgIcon>
        </Typography>
      </MUILink>
    );
  }

  return (
    <MUILink component={GatsbyLink} to={to}>
      <Typography component="span" variant={variant}>
        {children}
      </Typography>
    </MUILink>
  );
};

const useStyles = makeStyles(
  theme => ({
    icon: {
      fontSize: "inherit",
    },
  }),
  { name: "Link" }
);

Link.propTypes = {
  to: PropTypes.string,
  external: PropTypes.bool,
  children: PropTypes.string,
};

export default Link;
